import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="container-xl my-4">
      <h1 className="text-left">Cookie Policy</h1>
      <p className="text-muted">Effective Date: [Insert Date]</p>

      <p>
        Our website uses cookies to enhance your experience and gather data to understand user preferences. This Cookie Policy explains what cookies are, how we use them, and your choices regarding cookies.
      </p>

      <h2 className="mt-4 text-left">1. What Are Cookies?</h2>
      <p>
        Cookies are small text files stored on your device that help websites remember information about you, such as your preferences and activity.
      </p>

      <h2 className="mt-4 text-left">2. Types of Cookies We Use</h2>
      <p>We use the following types of cookies:</p>
      <ul>
        <li><strong>Essential Cookies:</strong> Necessary for the operation of our website.</li>
        <li><strong>Analytics Cookies:</strong> Help us analyze how users interact with our website.</li>
        <li><strong>Functional Cookies:</strong> Remember your preferences and settings.</li>
      </ul>

      <h2 className="mt-4 text-left">3. Managing Cookies</h2>
      <p>
        You can control cookie preferences through your browser settings. However, disabling certain cookies may impact your experience on our website.
      </p>

      <h2 className="mt-4 text-left">4. Third-Party Cookies</h2>
      <p>
        We may use third-party services that use cookies for analytics and advertising purposes. These cookies are governed by the privacy policies of the third parties.
      </p>

      <h2 className="mt-4 text-left">5. Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy periodically. The latest version will always be available on this page.
      </p>

      <h2 className="mt-4 text-left">Contact Us</h2>
      <p>
        If you have any questions about this Cookie Policy, please contact us at <strong>[Contact Email]</strong>.
      </p>
    </div>
  );
};

export default CookiePolicy;
